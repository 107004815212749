var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Mettre à jour le produit"}},[_c('b-card-text',[_c('span',[_vm._v("Modifier les détails du produit")])]),_c('div',{staticClass:"text-center"},[(_vm.load === 'true')?_c('b-spinner',{attrs:{"large":"","variant":"primary"}}):_vm._e()],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Produit"}},[_c('validation-provider',{attrs:{"name":"produit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Produit"},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"reference","label-for":"reference"}},[_c('validation-provider',{attrs:{"name":"reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reference","placeholder":"reference","state":errors.length > 0 ? false : null},model:{value:(_vm.product.reference),callback:function ($$v) {_vm.$set(_vm.product, "reference", $$v)},expression:"product.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"prix achat"}},[_c('validation-provider',{attrs:{"name":"purchase_price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"prix achat","type":"number"},model:{value:(_vm.product.purchase_price_ttc),callback:function ($$v) {_vm.$set(_vm.product, "purchase_price_ttc", $$v)},expression:"product.purchase_price_ttc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"prix de vente"}},[_c('validation-provider',{attrs:{"name":"sales price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"prix de vente","type":"number"},model:{value:(_vm.product.sales_price_ttc),callback:function ($$v) {_vm.$set(_vm.product, "sales_price_ttc", $$v)},expression:"product.sales_price_ttc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"quantité"}},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"quantité","type":"number"},model:{value:(_vm.product.quantity),callback:function ($$v) {_vm.$set(_vm.product, "quantity", $$v)},expression:"product.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"quantité minimum"}},[_c('validation-provider',{attrs:{"name":"minimum_quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"quantité minimum","type":"number"},model:{value:(_vm.product.minimum_quantity),callback:function ($$v) {_vm.$set(_vm.product, "minimum_quantity", $$v)},expression:"product.minimum_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Categorie","label-for":"Category"}},[_c('validation-provider',{attrs:{"name":"Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.categories,"clearable":false,"reduce":function (category) { return category.id; },"label":"name"},model:{value:(_vm.product.category),callback:function ($$v) {_vm.$set(_vm.product, "category", $$v)},expression:"product.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"TVA"}},[_c('validation-provider',{attrs:{"name":"vat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.vats,"clearable":false,"label":"value"},model:{value:(_vm.product.vat),callback:function ($$v) {_vm.$set(_vm.product, "vat", $$v)},expression:"product.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fournisseur","label-for":"supplier"}},[_c('validation-provider',{attrs:{"name":"supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.suppliers,"clearable":false,"reduce":function (supplier) { return supplier.id; },"label":"company_name"},model:{value:(_vm.product.supplier),callback:function ($$v) {_vm.$set(_vm.product, "supplier", $$v)},expression:"product.supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.updateProduct.apply(null, arguments)}}},[_vm._v(" Mise à jour ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }