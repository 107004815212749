<template>
  <b-card-code title="Mettre à jour le produit">
    <b-card-text>
      <span>Modifier les détails du produit</span>
    </b-card-text>

    <!-- form -->
    <div class="text-center">
      <b-spinner
        v-if="load === 'true'"
        large
        variant="primary"
      />
    </div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group label="Produit">
              <validation-provider
                #default="{ errors }"
                name="produit"
                rules="required"
              >
                <b-form-input
                  v-model="product.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Produit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="reference"
              label-for="reference"
            >
              <validation-provider
                #default="{ errors }"
                name="reference"
              >
                <b-form-input
                  id="reference"
                  v-model="product.reference"
                  placeholder="reference"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="prix achat">
              <validation-provider
                #default="{ errors }"
                name="purchase_price"
                rules="required"
              >
                <b-form-input
                  v-model="product.purchase_price_ttc"
                  :state="errors.length > 0 ? false : null"
                  placeholder="prix achat"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="prix de vente">
              <validation-provider
                #default="{ errors }"
                name="sales price"
                rules="required"
              >
                <b-form-input
                  v-model="product.sales_price_ttc"
                  :state="errors.length > 0 ? false : null"
                  placeholder="prix de vente"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="quantité">
              <validation-provider
                #default="{ errors }"
                name="quantity"
                rules="required"
              >
                <b-form-input
                  v-model="product.quantity"
                  :state="errors.length > 0 ? false : null"
                  placeholder="quantité"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="quantité minimum">
              <validation-provider
                #default="{ errors }"
                name="minimum_quantity"
                rules="required"
              >
                <b-form-input
                  v-model="product.minimum_quantity"
                  :state="errors.length > 0 ? false : null"
                  placeholder="quantité minimum"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Categorie"
              label-for="Category"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
              >
                <v-select
                  v-model="product.category"
                  :options="categories"
                  :clearable="false"
                  :reduce="(category) => category.id"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="TVA">
              <validation-provider
                #default="{ errors }"
                name="vat"
                rules="required"
              >
                <v-select
                  v-model="product.vat"
                  :options="vats"
                  :clearable="false"
                  label="value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Fournisseur"
              label-for="supplier"
            >
              <validation-provider
                #default="{ errors }"
                name="supplier"
                rules="required"
              >
                <v-select
                  v-model="product.supplier"
                  :options="suppliers"
                  :clearable="false"
                  :reduce="(supplier) => supplier.id"
                  label="company_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="updateProduct"
            >
              Mise à jour
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditProduct',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormFile,
    BSpinner,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      load: 'false',
      product: {
        id: null,
        name: '',
        reference: '',
        barcode: '',
        purchase_price_ttc: '',
        sales_price_ttc: '',
        quantity: '',
        minimum_quantity: '',
        description: '',
        category: '',
        vat: '',
        supplier: null,
        image: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters('category', ['categories']),
    ...mapGetters('vat', ['vats']),
    ...mapGetters('supplier', ['suppliers']),
  },
  created() {
    this.getProduct()
    this.getCategories()
    this.getVats()
    this.getSuppliers()
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    ...mapActions('vat', ['getVats']),
    ...mapActions('supplier', ['getSuppliers']),
    async getProduct() {
      const { data } = await axiosIns.get(`/products/${this.id}`)
      this.product = { ...data }
    },
    async updateProduct() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.load = 'true'
          try {
            const formData = new FormData()
            formData.append('reference', this.product.reference)
            formData.append('name', this.product.name)
            formData.append(
              'purchase_price_ttc',
              this.product.purchase_price_ttc,
            )
            formData.append('sales_price_ttc', this.product.sales_price_ttc)
            formData.append('quantity', this.product.quantity)
            formData.append('minimum_quantity', this.product.minimum_quantity)
            formData.append('category', this.product.category)
            formData.append('vat', this.product.vat.id)
            formData.append('vat_value', this.product.vat.value)
            formData.append('supplier', this.product.supplier)
            await axiosIns.put(`/products/update/${this.id}/`, formData)
            this.load = 'false'
            this.$router.push({ name: 'products' })
            setTimeout(() => {
              this.showToast(
                'success',
                'top-center',
                'produit modifié avec succès',
              )
            }, 1000)
          } catch (error) {
            console.error(error)
            this.load = 'false'
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champs obligatoire')
          }, 1000)
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
